import { Helmet } from 'react-helmet-async';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardContent, Container, Divider, Grid, Typography } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import CustomAppBar from '../../layouts/basic/CustomAppbar';
import Questions from '../dashboard/helpers/Questions';
import { withNoAuth } from '../../components/HOC/withNoAuth';
import Footer from '../../components/Footer';
import BenefitsList from '../../assets/data/BenefitsList';

function Benefits() {
  const isDesktop = useResponsive('up', 'md');
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>MedAffairs AI Benefits</title>
        <meta property="og:title" content="MedAffairs AI Benefits" />
        <meta
          property="og:description"
          content="Discover the Power of MedAffairs AI Benefits. Revolutionize medical affairs with advanced AI and tailored training. Embrace efficiency, informed decisions, and data-driven excellence. Experience the future today"
        />
        <meta
          property="og:image"
          content="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/ai/medaffairsai.png"
        />
        <meta property="og:url" content="https://medaffairsai.org/questions" />
      </Helmet>
      <Box
        sx={{
          m: isDesktop ? 2 : 0,
          borderRadius: isDesktop ? '15px' : '0px',
          pl: isDesktop ? 12 : 2,
          pr: isDesktop ? 12 : 2,
          height: isDesktop ? '600px' : '600px',
          background: `linear-gradient(45deg, #014459 0%, #000000 100%), radial-gradient(100% 225% at 100% 0%, #014459 0%, #000000 100%), linear-gradient(225deg, #707070 0%, #000000 100%), linear-gradient(135deg, #CDFFEB 10%, #CDFFEB 35%, #009F9D 35%, #ffffff 60%, #07456F 60%, #07456F 67%, #0F0A3C 67%, #0F0A3C 100%)`,
          backgroundSize: 'cover',
        }}
      >
        <CustomAppBar />
        <Container maxWidth="" sx={{ padding: '0px' }}>
          <Box
            sx={{
              pt: 9,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography sx={{ width: '100%' }} variant="h1" className="animate-charcter">
              Benefits of MedAffairs AI
            </Typography>
            <Typography variant="h4" sx={{ pt: 3, color: 'white', width: '75%', fontWeight: 500 }}>
              The Value of AI for Your Company
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Button
                sx={{
                  backgroundColor: 'white',
                  fontSize: '18px',
                  color: 'black',
                  '&:hover': { backgroundColor: '#dfdfdf' },
                  mr: 2,
                }}
                fullWidth={!isDesktop}
                size="large"
                onClick={(e) => navigate('/demo')}
              >
                Request a Demo
              </Button>

              <Button
                component={Link}
                to="/login"
                fullWidth={!isDesktop}
                sx={{
                  backgroundColor: 'white',
                  fontSize: '18px',
                  color: 'black',
                  '&:hover': { backgroundColor: '#dfdfdf' },
                  mt: isDesktop ? 0 : 2,
                }}
                size="large"
              >
                Get Started Today
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box sx={{ pt: 15, pb: 15 }}>
        <Container>
          <Box>
            <Typography variant="h3">What is MedAffais AI?</Typography>
            <Typography sx={{ fontWeight: 400, mt: 1.7 }} variant="h6">
              An AI-enabled, cloud-based machine learning tool that can be custom trained on your internal data
              (clinical studies, SOPs, articles, etc.). No more wasted time looking through shared drives and folders.
              Information is now at your fingertips!
            </Typography>
          </Box>
          <Typography variant="h3" sx={{ pt: 15 }}>
            Stay Compliant with Our Secure AI
          </Typography>
          <Grid container spacing={3} sx={{ pt: 7 }}>
            <Grid item md={4} container>
              <Card>
                <CardContent>
                  <Typography variant="h5">Unlock access to information, all available at your fingertips</Typography>
                  <Typography sx={{ fontWeight: 400, mt: 1.7 }} variant="h6">
                    Empowering people to move fast and effortless with a Q&A chatbot. You can ultimately better support
                    your internal/external stakeholders by providing information quickly.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} container>
              <Card>
                <CardContent>
                  <Typography variant="h5">Extend learning & ongoing development of team and patients</Typography>
                  <Typography sx={{ fontWeight: 400, mt: 1.7 }} variant="h6">
                    Get data on all queries to turn that information into actionable insights.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} container>
              <Card>
                <CardContent>
                  <Typography variant="h5">Your team expert is available 24/7 for questions!</Typography>
                  <Typography sx={{ fontWeight: 400, mt: 1.7 }} variant="h6">
                    No more searching aimlessly on the internet or worrying about whether an uncustomized AI tool is
                    reliable enough for your company. Save company time instantly.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={{ pt: 10, pb: 15 }}>
        <Container>
          <Typography variant="h3">Information when you need it. Now.</Typography>
          <Box sx={{ pt: 4 }}>
            <Typography variant="h5">Medical Affairs Specific AI Solutions</Typography>
            <Typography sx={{ fontWeight: 400, mt: 2 }} variant="h6">
              Avoid development costs and downtime, implement well-rounded custom solutions customized for the pharma
              industry.
            </Typography>
          </Box>
          <Box sx={{ pt: 4 }}>
            <Typography variant="h5">Invest in Digital Solutions to Mitigate Risk</Typography>
            <Typography sx={{ fontWeight: 400, mt: 2 }} variant="h6">
              AI is more effective and accurate, compared to human counterparts. Responses from AI can help ensure
              seamless Q&A and reduce costs.
            </Typography>
          </Box>
          <Box sx={{ pt: 4 }}>
            <Typography variant="h5">Data Collection</Typography>
            <Typography sx={{ fontWeight: 400, mt: 2 }} variant="h6">
              Get data on the internal and external queries to influence company plans, strategies and pivot points.
            </Typography>
          </Box>

          <Box sx={{ pt: 4 }}>
            <Typography variant="h5">Improve Efficiency & Productivity</Typography>
            <Typography sx={{ fontWeight: 400, mt: 2 }} variant="h6">
              Exponentially increase your team's productivity with AI. Allocate that time towards projects that can help
              drive real time intelligence and effectiveness.
            </Typography>
          </Box>
        </Container>
      </Box>

      <Box sx={{ pt: 10, pb: 10 }}>
        <Container>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <Typography variant="h3">Don’t forget what our secure AI can do for your team!</Typography>
              <Box sx={{ pt: 4 }}>
                <ul>
                  <li style={{ marginTop: '10px', display: 'flex', alignItems: 'start' }}>
                    <Typography variant="h3">•</Typography>
                    <Typography sx={{ fontWeight: 400, mt: 1.3, ml: 2 }} variant="h6">
                      Stay ahead of the competition, get your information quickly
                    </Typography>
                  </li>
                  <li style={{ marginTop: '10px', display: 'flex', alignItems: 'start' }}>
                    <Typography variant="h3">•</Typography>
                    <Typography sx={{ fontWeight: 400, mt: 1.3, ml: 2 }} variant="h6">
                      Save time & money
                    </Typography>
                  </li>
                  <li style={{ marginTop: '10px', display: 'flex', alignItems: 'start' }}>
                    <Typography variant="h3">•</Typography>
                    <Typography sx={{ fontWeight: 400, mt: 1.3, ml: 2 }} variant="h6">
                      Streamline resources
                    </Typography>
                  </li>
                  <li style={{ marginTop: '10px', display: 'flex', alignItems: 'start' }}>
                    <Typography variant="h3">•</Typography>
                    <Typography sx={{ fontWeight: 400, mt: 1.3, ml: 2 }} variant="h6">
                      Provide accurate & timely information in seconds. Stay compliant!
                    </Typography>
                  </li>
                  <li style={{ marginTop: '10px', display: 'flex', alignItems: 'start' }}>
                    <Typography variant="h3">•</Typography>
                    <Typography sx={{ fontWeight: 400, mt: 1.3, ml: 2 }} variant="h6">
                      Minimize Risk & Improve Compliance
                    </Typography>
                  </li>
                </ul>
              </Box>
            </Grid>
            <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img
                src="https://images.ctfassets.net/ohqd46y4hkj2/70H1NFhcqy15aTI3roMzBe/a869748e3d916ac2c405e32d3a0bafe5/Blue_Minimalist_Artificial_Intelligence_Technology_Logo__1_.svg"
                alt="ai-img"
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <Button
              sx={{
                backgroundColor: '#1b6f83',
                fontSize: '18px',
                color: 'white',
                '&:hover': { backgroundColor: '#104c5a' },
                p: 4.5,
              }}
              size="large"
              variant=""
              onClick={(e) => navigate('/demo')}
            >
              Book a demo to catch up with the competition!
            </Button>
          </Box>
        </Container>
      </Box>

      <Footer />
    </>
  );
}

export default withNoAuth(Benefits);
