import { Helmet } from 'react-helmet-async';
import { Box, Container, Typography } from '@mui/material';
import { withAuth } from '../../components/HOC/withAuth';
import AuthAppBar from '../../layouts/basic/AuthAppBar';
import ViewOrTrainData from './helpers/ViewOrTrainData';
import TrainDocuments from './helpers/TrainDocuments';
import useResponsive from '../../hooks/useResponsive';

function Train() {
  const isDesktop = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> Medical Affairs AI</title>
      </Helmet>
      <Box
        className="hero-banner"
        sx={{
          pl: isDesktop ? 12 : 2,
          pr: isDesktop ? 12 : 2,
          height: '600px',
          backgroundImage: `url('https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/biologics-ai/bg.png')`,
          backgroundSize: 'cover',
        }}
      >
        <AuthAppBar />
        <Container sx={{ padding: '0px' }}>
          <Box sx={{ pt: 4 }}>
            {/* Mango Change */}
            {/* <Typography sx={{ color: 'white' }} variant="h2">
              Train Field Reimbursement & Prior Auth AI
            </Typography> */}
            <Typography sx={{ color: 'white' }} variant="h2">
              Train MedAffairs AI
            </Typography>
            <Typography sx={{ pt: 3, color: 'white' }} variant="h4">
              Effortlessly Customize Training for Your Internal Data
            </Typography>
          </Box>
          <Box sx={{ mt: 3 }} className="flex justify-center align-center">
            <img
              src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/train-puzzle.svg"
              alt="Train AI"
            />
          </Box>
        </Container>
      </Box>

      <Container>
        <TrainDocuments />
      </Container>
    </>
  );
}

export default withAuth(Train);
