import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SendIcon from '@mui/icons-material/Send';
import FlareIcon from '@mui/icons-material/Flare';
import StartIcon from '@mui/icons-material/Start';
import GppGoodIcon from '@mui/icons-material/GppGood';
import StarIcon from '@mui/icons-material/Star';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import ContentCut from '@mui/icons-material/ContentCut';
import { Divider, ListItemIcon, ListItemText, MenuList, Paper, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import useResponsive from '../../hooks/useResponsive';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function MedAffairsMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'md');
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBenefits = () => {
    navigate('/benefits');
    handleClose();
  };

  const handleWhy = () => {
    navigate('/why-medaffairs-ai');
    handleClose();
  };

  const handleFAQs = () => {
    navigate('/questions');
    handleClose();
  };

  const handleDemo = () => {
    navigate('/demo');
    handleClose();
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        size="large"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          fontSize: '18px',
          color: 'white',
        }}
      >
        Learn More
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleBenefits} disableRipple sx={{ p: 2 }}>
          <GppGoodIcon />
          Benefits of MedAffairs AI
        </MenuItem>
        <MenuItem onClick={handleWhy} disableRipple sx={{ p: 2 }}>
          <StarIcon />
          Why MedAffairs AI?
        </MenuItem>
        <MenuItem onClick={handleFAQs} disableRipple sx={{ p: 2 }}>
          <LiveHelpIcon />
          FAQs
        </MenuItem>
        <MenuItem onClick={handleDemo} disableRipple sx={{ p: 2 }}>
          <SendIcon />
          Request a Demo
        </MenuItem> */}

        <Paper sx={{ width: 320, maxWidth: '100%' }}>
          <MenuList>
            <MenuItem onClick={handleBenefits}>
              <ListItemIcon>
                <GppGoodIcon />
              </ListItemIcon>
              <ListItemText>Benefits of MedAffairs AI</ListItemText>
              <Typography variant="body2" color="text.secondary">
                <StartIcon />
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleWhy}>
              <ListItemIcon>
                <StarIcon />
              </ListItemIcon>
              <ListItemText>Why MedAffairs AI?</ListItemText>
              <Typography variant="body2" color="text.secondary">
                <StartIcon />
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleFAQs}>
              <ListItemIcon>
                <LiveHelpIcon />
              </ListItemIcon>
              <ListItemText>FAQs</ListItemText>
              <Typography variant="body2" color="text.secondary">
                <StartIcon />
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleDemo}>
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText>Request a Demo</ListItemText>
            </MenuItem>
          </MenuList>
        </Paper>
      </StyledMenu>
    </div>
  );
}
