import { Box, Button, Card, CardContent, Container, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Tab1 from './Tab1';
import Tab2 from './Tab2';
import Tab3 from './Tab3';
import Tab4 from './Tab4';
import Tab5 from './Tab5';
import useResponsive from '../../../hooks/useResponsive';

export default function DemoVideos() {
  const [tabValue, setTabValue] = useState('Internal Data Search');
  const [value, setValue] = useState(0);
  const isDesktop = useResponsive('up', 'md');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box>
        <Card
          sx={{
            backgroundColor: '#e2e1e1',
            color: '#021f28',
          }}
        >
          <CardContent className="flex justify-center align-center">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              centered
            >
              <Tab label="Internal Data Search" onClick={(e) => setTabValue('Internal Data Search')} />
              <Tab label="External Data Search" onClick={(e) => setTabValue('External Data Search')} />
              <Tab label="Training MedAffairs AI" onClick={(e) => setTabValue('Training MedAffairs AI')} />
              <Tab label="Documents" onClick={(e) => setTabValue('Documents')} />
              <Tab label="Access Control" onClick={(e) => setTabValue('Access Control')} />
            </Tabs>
          </CardContent>
        </Card>

        <Card
          sx={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: '#021f28',
          }}
        >
          <CardContent sx={{ p: isDesktop ? '0px' : '20px', padding: '0px' }}>
            <Box>
              {tabValue && tabValue === 'Internal Data Search' ? (
                <>
                  <Tab1 />
                </>
              ) : tabValue === 'External Data Search' ? (
                <>
                  <Tab2 />
                </>
              ) : tabValue === 'Training MedAffairs AI' ? (
                <>
                  <Tab3 />
                </>
              ) : tabValue === 'Documents' ? (
                <>
                  <Tab4 />
                </>
              ) : tabValue === 'Access Control' ? (
                <>
                  <Tab5 />
                </>
              ) : (
                <></>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
