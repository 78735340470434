import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import AuthAppBar from '../../layouts/basic/AuthAppBar';
import { useGetUserInfo } from '../../hooks/useGetUserInfo';
import Spinner from '../../components/ui/Spinner';
import SettingsContainer from './helpers/SettingsContainer';
import useResponsive from '../../hooks/useResponsive';

export default function Settings() {
  const { userCompanyDetails, userCompanyDetailsLoading } = useGetUserInfo();
  const isDesktop = useResponsive();
  if (userCompanyDetailsLoading) {
    return <Spinner />;
  }
  return (
    <Box>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Box
        className="hero-banner"
        sx={{
          pl: isDesktop ? 12 : 2,
          pr: isDesktop ? 12 : 2,
          height: '100px',
          backgroundImage: `url('https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/biologics-ai/bg.png')`,
          backgroundSize: 'cover',
        }}
      >
        <AuthAppBar />
      </Box>
      <Container sx={{ mt: 6, pb: 10 }}>
        {/* <CompanyCard company={userCompanyDetails.company} /> */}
        {userCompanyDetails && userCompanyDetails.company && userCompanyDetails.company.company_access === 'demo' ? (
          <>
            <Card sx={{ color: 'white', backgroundColor: '#10243a' }}>
              <CardContent>
                <Typography variant="h5">👋 MedAffairs AI Demo</Typography>
                <Typography>
                  Welcome to the trial demo! You're currently exploring a limited version of our software. Upgrade to
                  the full version for access to all features and functionality.
                </Typography>
              </CardContent>
            </Card>
          </>
        ) : (
          <></>
        )}
        <SettingsContainer user={userCompanyDetails.user} company={userCompanyDetails.company} />
      </Container>
    </Box>
  );
}
