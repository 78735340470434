import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Container, Typography, Box, CircularProgress, Backdrop } from '@mui/material';
// components
// sections

import CustomAppBar from '../../layouts/basic/CustomAppbar';
import SearchBoxes from './helpers/SearchBoxes';
import Questions from './helpers/Questions';
import useResponsive from '../../hooks/useResponsive';
import { withAuth } from '../../components/HOC/withAuth';
import { useGetUserInfo } from '../../hooks/useGetUserInfo';
import AuthAppBar from '../../layouts/basic/AuthAppBar';
import Spinner from '../../components/ui/Spinner';

// ----------------------------------------------------------------------

function DashboardAppPage() {
  const isDesktop = useResponsive('up', 'md');
  const { userCompanyDetails, userCompanyDetailsLoading } = useGetUserInfo();

  if (userCompanyDetailsLoading) {
    return <Spinner />;
  }
  return (
    <>
      <Helmet>
        <title> Medaffairs AI</title>
      </Helmet>
      <Box
        className="hero-banner"
        sx={{
          pl: isDesktop ? 12 : 2,
          pr: isDesktop ? 12 : 2,
          height: '600px',
          backgroundImage: `url('https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/biologics-ai/bg.png')`,
          backgroundSize: 'cover',
        }}
      >
        <AuthAppBar />
        <Container maxWidth="" sx={{ padding: '0px' }}>
          <Box sx={{ pt: 4 }}>
            {/* Mango Change */}
            {/* <Typography sx={{ color: 'white' }} variant="h2">
              Field Reimbursement & Prior Auth AI
            </Typography> */}
            <Typography sx={{ color: 'white' }} variant="h2">
              MedAffairs AI
            </Typography>

            {/* Mango Change */}
            {/* <Typography sx={{ pt: 3, color: 'white' }} variant="h4">
              Enhance Your Field Reimbursement Team with a Fast & Secure AI Tool
            </Typography> */}

            <Typography sx={{ pt: 3, color: 'white' }} variant="h4">
              Real-time Medical Affairs Insights When You Need It
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box sx={{ width: '100%', marginTop: '-290px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <SearchBoxes userCompanyDetails={userCompanyDetails} />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Container maxWidth="" sx={{ padding: '0px' }}>
          <Box
            sx={{
              pt: isDesktop ? 8 : 2,
              pb: isDesktop ? 8 : 2,
              paddingLeft: isDesktop ? '110px' : '10px',
              paddingRight: isDesktop ? '110px' : '10px',
            }}
          >
            <Questions />
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default withAuth(DashboardAppPage);
