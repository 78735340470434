import axios from 'axios';
import toast from 'react-hot-toast';
import { Auth } from 'aws-amplify';
import { UPDATE_AUTH_USER_API } from '../../API';
import { CREATE_USER_IN_PROGRESS } from '../../types';
import { getCompanyUsers } from './companyUsers';

export const createUser = (userObj) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;
  dispatch({
    type: CREATE_USER_IN_PROGRESS,
    payload: true,
  });
  try {
    axios
      .post(
        UPDATE_AUTH_USER_API,
        {
          email,
          user: JSON.stringify(userObj),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          },
        }
      )
      .then((res) => {
        console.log('res', res.data);
        if (res && res.data && res.data.success === true) {
          toast.success('Succesfully created user. An invite email has been sent.');
          dispatch(getCompanyUsers());
          dispatch({
            type: CREATE_USER_IN_PROGRESS,
            payload: false,
          });
        } else {
          toast.error(res.data.error_message);
          dispatch({
            type: CREATE_USER_IN_PROGRESS,
            payload: false,
          });
        }
      })
      .catch((error) => {
        console.log('err uploading document', error);
        toast.error('Unable to create the user! Please try again');
        dispatch({
          type: CREATE_USER_IN_PROGRESS,
          payload: false,
        });
      });
  } catch (err) {
    dispatch({
      type: CREATE_USER_IN_PROGRESS,
      payload: false,
    });
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log('error', err.response.data);
      console.log('error', err.response.status);
    } else if (err.request) {
      console.log('error', err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};
