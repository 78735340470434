import { Box, Typography } from '@mui/material';
import VideoLoopComponent from './VideoLoopComponent';

export default function Tab1() {
  return (
    <Box sx={{ mt: 5 }}>
      <Box className="flex justify-center align-center column">
        <Typography variant="h2">Internal Data Search</Typography>
        <Typography sx={{ textAlign: 'center', fontSize: '18px' }}>
          Effortlessly retrieve answers by querying your internally uploaded documents. Each response is accompanied by
          the corresponding resource document for easy reference
        </Typography>
      </Box>
      <Box sx={{ mt: 5 }}>
        <VideoLoopComponent
          url={
            'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/videos/internal-data.mp4'
          }
        />
      </Box>
    </Box>
  );
}
