export function UniqueFileList(fileList) {
  const getTopUniqueFiles = (files) => {
    // Remove any spaces from file names and create a set to store unique file names
    const cleanedFiles = new Set(files.map((file) => file.trim()));

    // If all files are the same, return one as the unique file
    if (cleanedFiles.size === 1) {
      return [cleanedFiles.values().next().value];
    }

    // Create an array from the set and sort it by the count of occurrences in the original array
    const uniqueFiles = Array.from(cleanedFiles).sort((a, b) => {
      const countA = files.filter((file) => file.trim() === a).length;
      const countB = files.filter((file) => file.trim() === b).length;
      return countB - countA;
    });

    return uniqueFiles.slice(0, 3);
  };
  const topUniqueFiles = getTopUniqueFiles(fileList);
  return topUniqueFiles;
}
