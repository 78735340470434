import axios from 'axios';
import { Auth } from 'aws-amplify';
import { v4 } from 'uuid';
import { TRAINING_IN_PROGRESS, TRAINING_COMPLETED } from '../../types';
import { TRAIN_API } from '../../API';
import getFileSize from '../../../helpers/getFileSize';
import { uploadDocuments, uploadDocumentstoExternalDb } from '../documents/uploadDocuments';

export const trainDocuments = (chunkedOutputs) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;
  const files = chunkedOutputs.map((item) => item.file);

  dispatch({
    type: TRAINING_IN_PROGRESS,
    payload: { training: true, files },
  });

  // Helper function to send a chunk to the API
  const sendChunk = async (file, chunks, fileId, counter) => {
    try {
      const response = await axios.post(
        TRAIN_API,
        {
          file,
          chunks,
          email,
          fileId,
          counter,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  // Arrays to store initial file training info and output results
  const fileTrainingInitial = [];
  const fileTrainingOutput = [];

  // Loop through chunkedOutputs using Promise.all
  await Promise.all(
    chunkedOutputs.map(async (element) => {
      // Generate a unique file ID
      const fileId = v4();
      const chunkSubsetSize = 50;
      const totalChunks = element.chunks.length;
      const totalIterations = Math.ceil(totalChunks / chunkSubsetSize);

      // Store initial file training info
      fileTrainingInitial.push({ id: fileId, chunkIteration: totalIterations });
      const promises = [];

      // Split chunks and send each chunk subset
      let counter = 0;
      for (let i = 0; i < element.chunks.length; i += chunkSubsetSize) {
        counter += 1;
        const chunkSubset = element.chunks.slice(i, i + chunkSubsetSize);
        promises.push(
          sendChunk(
            { name: element.file.name, size: getFileSize(element.file.size), type: element.file.type },
            chunkSubset,
            fileId,
            counter
          )
        );
      }

      // Wait for all promises to resolve
      const results = await Promise.all(promises);

      // Check if all chunks were successfully uploaded for this file
      const allChunksUploaded =
        results.length === totalIterations && results.every((result) => result && result.success);

      // Extract the name from the first successful result
      const successResult = results.find((result) => result);
      const fileName = successResult ? successResult.name : '';
      const message = successResult ? successResult.message : '';

      // Store the file training output
      fileTrainingOutput.push({
        id: fileId,
        success: allChunksUploaded,
        name: fileName,
        message,
      });
    })
  );

  // Log the final output
  dispatch(uploadDocuments(fileTrainingOutput, files));
  dispatch(uploadDocumentstoExternalDb(fileTrainingOutput, files));

  dispatch({
    type: TRAINING_IN_PROGRESS,
    payload: { training: false, files: [] },
  });

  dispatch({
    type: TRAINING_COMPLETED,
    payload: fileTrainingOutput,
  });
};
