import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import AppBar from '@mui/material/AppBar';
import { useDispatch } from 'react-redux';

import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, Card, CardContent, Container, Grid } from '@mui/material';
import FileTypeImage3D from '../../../helpers/FileTypeImage3D';
import { downloadFileAndGetText } from '../../../store/actions/documents/download';

export default function SelectedMoreInfoDocument({ document, setDocument }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setDocument({});
    setOpen(false);
  };

  React.useEffect(() => {
    if (document && document.id) {
      dispatch(downloadFileAndGetText(document.document_name));
      setOpen(true);
    }
  }, [document]);

  return document && document.id ? (
    <>
      <div>
        <Dialog fullScreen open={open} onClose={handleClose}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Document Details
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                save
              </Button>
            </Toolbar>
          </AppBar>

          <Container maxWidth="xl" sx={{ mt: 4 }}>
            <Grid container spacing={3}>
              <Grid item md={8}>
                <Card>
                  <CardContent>
                    <Box className="flex justify-between align-center">
                      <Typography variant="h6">Compliance Checker</Typography>
                      <Button variant="contained" size="large">
                        New Compliance
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">Document Info</Typography>
                    <Box sx={{ mt: 3 }} className="flex align-center">
                      <FileTypeImage3D name={document.document_name} customWidth={'500px'} />
                      <Typography sx={{ mt: 1, ml: 2 }}>{document.document_name}</Typography>
                    </Box>
                    <Typography>2MB</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Dialog>
      </div>
    </>
  ) : (
    <></>
  );
}
