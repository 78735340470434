import Papa from 'papaparse';
import toast from 'react-hot-toast';

export const processExcelFile = async (file) => {
  try {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const fileContent = event.target.result;
        Papa.parse(fileContent, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            if (result.errors.length > 0 || result.data.length === 0) {
              // You can handle errors or validation separately
              toast.error(
                'Looks like your excel file is not in a good format or is missing header column names! Please try again'
              );
              resolve({ success: false, file, chunks: null });
            } else {
              // Convert the output data to a JSON string
              let dataString = JSON.stringify(result.data);

              // Split the JSON string into chunks of a maximum of 1000 characters each
              const chunks = [];
              while (dataString.length > 0) {
                const chunk = dataString.substring(0, 1000);
                chunks.push(chunk);
                dataString = dataString.substring(1000);
              }

              resolve({ success: true, file, chunks });
            }
          },
        });
      };

      reader.readAsText(file);
    });
  } catch (error) {
    console.error('Error processing the file:', error);
    return { success: false, file, chunks: null };
  }
};
