import { Box, Container, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function AIUseCases() {
  const types = [
    'Phase IIIb/IV Label Expansion',
    'KOL support',
    'Driving Strategy',
    'Accessing Information Seamlessly',
    'Improving HCP Access',
    'Supporting Internal Stakeholders',
    'Clinical Trials',
    'Learning & Development',
  ];
  return (
    <Box sx={{ pt: 15, pb: 15 }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Typography variant="h2" sx={{ color: 'white', pb: 5 }}>
              {/* Who is CNMAP™ for? */}
              AI Use Cases & Medical Affairs
            </Typography>
          </Grid>
          {types &&
            types.length >= 1 &&
            types.map((item) => (
              <>
                <Grid item md={6} xs={12}>
                  <Typography variant="h5" sx={{ color: 'white' }} className="flex align-center">
                    <CheckCircleIcon style={{ marginRight: '7px' }} />
                    {item}
                  </Typography>
                </Grid>
              </>
            ))}
        </Grid>
      </Container>
    </Box>
  );
}
