import { toast } from 'react-hot-toast';
import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import FileSelectionLogic from './FileSelectLogic';
import SelectedFilesGrid from './SelectedFiles';
import TrainingInProgress from '../../../components/training-progress';
import { trainDocuments } from '../../../store/actions/train/trainDocuments';
import { useGetUserInfo } from '../../../hooks/useGetUserInfo';
import Spinner from '../../../components/ui/Spinner';
import useResponsive from '../../../hooks/useResponsive';

const CustomDropZone = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [tempSelected, setTempSelected] = useState([]);
  const dispatch = useDispatch();
  const isDesktop = useResponsive('up', 'md');

  const trainingInProgress = useSelector((state) => state.training.trainingInProgress);
  const { userCompanyDetails, userCompanyDetailsLoading } = useGetUserInfo();

  const [chunkedOutputs, setChunkedOutputs] = useState([]);
  const [chunkingInProgress, setChunkingInProgress] = useState(false);

  const handleDeleteFile = (fileToDelete) => {
    setChunkedOutputs((prevSelectedFiles) => prevSelectedFiles.filter((file) => file.file.name !== fileToDelete.name));
    setSelectedFile((prevSelectedFiles) => prevSelectedFiles.filter((file) => file.name !== fileToDelete.name));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileSelect = (files) => {
    setSelectedFile(files);
  };

  const handleCancelTrain = () => {
    setSelectedFile([]);
    setTempSelected([]);
    setChunkedOutputs([]);
    setChunkingInProgress(false);
  };

  const handleTrainDocument = async () => {
    if (!chunkingInProgress && chunkedOutputs && !trainingInProgress) {
      dispatch(trainDocuments(chunkedOutputs));
      setSelectedFile([]);
      setTempSelected([]);
      setChunkedOutputs([]);
      setChunkingInProgress(false);
    } else {
      toast.error('Please make sure you have uploaded file(s) before training.');
    }
  };

  if (userCompanyDetailsLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Grid sx={{ pt: isDesktop ? 5 : 1 }} container spacing={2} className="flex justify-center align-center">
        <Grid item md={12} xs={12}>
          <Grid container spacing={3} className="flex justify-center align-center">
            {userCompanyDetails &&
            userCompanyDetails.company &&
            userCompanyDetails.company.company_access === 'demo' ? (
              <>
                <Grid item md={11}>
                  <Card sx={{ color: 'white', backgroundColor: '#10243a' }}>
                    <CardContent>
                      <Typography variant="h5">👋 MedAffairs AI Demo</Typography>
                      <Typography>
                        The Demo Allows upto 3 documents to be trained to MedAffairs AI. Upgrade to Enterprise for
                        unlimited document training.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            ) : (
              <></>
            )}

            <Grid item md={11} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <FileSelectionLogic
                trainingInProgress={trainingInProgress}
                selectedFile={selectedFile}
                chunkedOutputs={chunkedOutputs}
                setSelectedFile={setSelectedFile}
                onSelect={handleFileSelect}
                setChunkedOutputs={setChunkedOutputs}
                handleDragOver={handleDragOver}
                setChunkingInProgress={setChunkingInProgress}
                tempSelected={tempSelected}
                setTempSelected={setTempSelected}
                userCompanyDetails={userCompanyDetails}
              />
            </Grid>
            <Grid item md={11} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <SelectedFilesGrid
                chunkingInProgress={chunkingInProgress}
                selectedFile={selectedFile}
                chunkedOutputs={chunkedOutputs}
                handleDeleteFile={handleDeleteFile}
                handleTrainDocument={handleTrainDocument}
                handleCancelTrain={handleCancelTrain}
              />
            </Grid>
            <Grid item md={11} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <Card>
                <CardContent>
                  <Typography variant="h5">Quick Links</Typography>
                  <Typography>Use these external tools to help with your document training</Typography>

                  <Grid container spacing={3} sx={{ mt: 2 }}>
                    <Grid item md={3}>
                      <Card>
                        <CardMedia
                          image={
                            'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/multiple-pdfss.svg'
                          }
                          component="img"
                          alt="multiplePdf"
                          height="120"
                        />
                        <CardContent>
                          <Typography variant="h6">Split PDFs</Typography>
                          <Typography sx={{ fontSize: '13px', mt: 1 }}>
                            Divide a large PDF file into several smaller-sized files.
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item md={3}>
                      <Card>
                        <CardMedia
                          image={
                            'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/ppt-to-pdf.svg'
                          }
                          component="img"
                          alt="multiplePdf"
                          height="120"
                        />
                        <CardContent>
                          <Typography variant="h6">PowerPoint to PDF</Typography>
                          <Typography sx={{ fontSize: '13px', mt: 1 }}>
                            Convert your powerpoints to PDF documents
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item md={3}>
                      <Card>
                        <CardMedia
                          image={
                            'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/anything-to-text.svg'
                          }
                          component="img"
                          alt="multiplePdf"
                          height="120"
                        />
                        <CardContent>
                          <Typography variant="h6">File to Text</Typography>
                          <Typography sx={{ fontSize: '13px', mt: 1 }}>Convert any document to a text file</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item md={3}>
                      <Card>
                        <CardMedia
                          image={
                            'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/image-converter.svg'
                          }
                          component="img"
                          alt="formatter"
                          height="120"
                        />
                        <CardContent>
                          <Typography variant="h6">Image Formatting</Typography>
                          <Typography sx={{ fontSize: '13px', mt: 1 }}>Format your image to jpg or jpeg</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    {/* <Grid item md={3}>
                      <Card>ee</Card>
                    </Grid>
                    <Grid item md={3}>
                      <Card>ee</Card>
                    </Grid> */}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TrainingInProgress />
    </>
  );
};

export default CustomDropZone;
