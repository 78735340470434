import axios from 'axios';
import { Auth } from 'aws-amplify';
import { toast } from 'react-hot-toast';
import { SEARCH_OUTPUT, SEARCH_LOADING, REMOVE_SEARCH_OUTPUTS } from '../types';
import { SEARCH_API } from '../API';

export const searchChat = (search, isSwitchOn, userCompanyDetails) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;
  const company = userCompanyDetails.company;
  const companyId = company.id;

  dispatch({
    type: SEARCH_LOADING,
    payload: true,
  });
  console.log(search, isSwitchOn);
  try {
    axios
      .post(
        SEARCH_API,
        {
          email,
          isSwitchOn,
          search,
          companyId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          },
        }
      )
      .then((res) => {
        console.log('res', res.data);
        dispatch({
          type: SEARCH_OUTPUT,
          payload: res.data,
        });
        dispatch({
          type: SEARCH_LOADING,
          payload: false,
        });
      })
      .catch((error) => {
        toast.error('Error generating an output. Please try again!');
        dispatch({
          type: SEARCH_LOADING,
          payload: false,
        });
      });
  } catch (err) {
    toast.error('Woops, we were unable to get your answer! Please try again.');
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log('error', err.response.data);
      console.log('error', err.response.status);
    } else if (err.request) {
      console.log('error', err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};

export const removeConversation = () => async (dispatch) => {
  dispatch({
    type: REMOVE_SEARCH_OUTPUTS,
    payload: '',
  });
};
