import { CLEAR_ALL, GET_COMPANY_DOCUMENTS, DOCUMENTS_DELETED, DOCUMENT_DELETION_IN_PROGRESS } from '../types';

const initialState = {
  trainedDocumentsLoading: true,
  trainedDocuments: [],
  documentDeletionInProgress: false,
};

export default function Caller(state = initialState, action) {
  switch (action.type) {
    case DOCUMENT_DELETION_IN_PROGRESS:
      return {
        ...state,
        documentDeletionInProgress: action.payload,
      };
    case GET_COMPANY_DOCUMENTS:
      return {
        ...state,
        trainedDocuments: action.payload,
        trainedDocumentsLoading: false,
      };

    case DOCUMENTS_DELETED: {
      console.log('current documents', state.trainedDocuments.documents);
      console.log('incoming', action.payload);
      const updatedDocuments = state.trainedDocuments.documents.filter((document) => {
        const foundPayload = action.payload.find(
          (payloadItem) => payloadItem.success && payloadItem.document === document.document_name
        );
        if (foundPayload) {
          return !foundPayload; // Exclude documents that have a matching success:true entry in action.payload
        }
        return state.trainedDocuments;
      });
      return {
        ...state,
        trainedDocuments: { ...state.trainedDocuments, documents: updatedDocuments }, // Update 'documents' property,
        trainedDocumentsLoading: false,
      };
    }
    case CLEAR_ALL:
      return {
        ...state,
        trainedDocumentsLoading: true,
        trainedDocuments: [],
        documentDeletionInProgress: false,
      };

    default:
      return state;
  }
}
