import * as React from 'react';
import { Button } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Logo from '../../components/logo/Logo';
import { useGetUser } from '../../hooks/useGetUser';
import MedAffairsMenu from './MedAffairsMenu';
import useResponsive from '../../hooks/useResponsive';
import MedAffairsMenuMobile from './MedAffairsMenuMobile';
import { clearALLStates } from '../../store/actions/clear';
import MedAffairsMenuMobileAuth from './MedAffairsMenuMobileAuth';

function AuthAppBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loading } = useGetUser();
  const isDesktop = useResponsive('up', 'md');
  const location = useLocation(); // Get the current location
  const isActive = (path) => location.pathname === path; // Helper function to check if a link is active

  const logout = async () => {
    try {
      await Auth.signOut();
      dispatch(clearALLStates());
      navigate('/login');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  return (
    <AppBar position="static" style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
      <Container sx={{ padding: '0px', pt: 4 }}>
        <Toolbar disableGutters sx={{ display: 'block' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {isDesktop ? <></> : <MedAffairsMenuMobileAuth />}

            <Logo />
            {loading ? (
              <></>
            ) : user ? (
              <>
                {isDesktop ? (
                  <>
                    <Box>
                      <Link
                        to="/app"
                        style={{
                          textDecoration: 'none',
                          color: 'white',
                          marginRight: '20px',
                          borderBottom: isActive('/app') ? '2px solid white' : 'none', // Apply borderBottom conditionally
                          paddingBottom: '5px',
                        }}
                      >
                        Search AI
                      </Link>
                      <Link
                        to="/train"
                        style={{
                          borderBottom: isActive('/train') ? '2px solid white' : 'none', // Apply borderBottom conditionally
                          paddingBottom: '5px',
                          textDecoration: 'none',
                          color: 'white',
                          marginLeft: '20px',
                          marginRight: '20px',
                        }}
                      >
                        Train AI
                      </Link>
                      <Link
                        to="/documents"
                        style={{
                          borderBottom: isActive('/documents') ? '2px solid white' : 'none', // Apply borderBottom conditionally
                          paddingBottom: '5px',
                          textDecoration: 'none',
                          color: 'white',
                          marginLeft: '20px',
                          marginRight: '20px',
                        }}
                      >
                        Documents
                      </Link>
                      <Link
                        to="/settings"
                        style={{
                          borderBottom: isActive('/settings') ? '2px solid white' : 'none', // Apply borderBottom conditionally
                          paddingBottom: '5px',
                          textDecoration: 'none',
                          color: 'white',
                          marginLeft: '20px',
                          marginRight: '20px',
                        }}
                      >
                        Settings
                      </Link>
                    </Box>

                    <Button
                      variant="container"
                      size="large"
                      sx={{
                        ml: 7.5,
                        mr: 2,
                        backgroundColor: 'white',
                        fontSize: '18px',
                        color: 'black',
                        '&:hover': { backgroundColor: '#dfdfdf' },
                      }}
                      onClick={logout}
                    >
                      Logout
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Box sx={{ display: 'flex' }}>
                {isDesktop ? <MedAffairsMenu /> : <></>}

                <Button
                  variant="container"
                  size="large"
                  sx={{
                    ml: isDesktop ? 4 : 1,
                    backgroundColor: 'white',
                    fontSize: '18px',
                    color: 'black',
                    '&:hover': { backgroundColor: '#dfdfdf' },
                  }}
                  onClick={(e) => navigate('/login')}
                >
                  Login
                </Button>
              </Box>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default AuthAppBar;
