import axios from 'axios';
import { Auth } from 'aws-amplify';
import { UPLOADED_DOCUMENTS } from '../../types';
import { GET_DOCUMENT_UPLOAD_URL, UPLOAD_DOCUMENT_TO_EXTERNAL_DB_API } from '../../API';

export const uploadDocumentstoExternalDb = (fileTrainingOutput, files) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;

  const updatedFileTrainingOutput = fileTrainingOutput.map((outputItem) => {
    if (outputItem.success === true) {
      const matchingFile = files.find((file) => file.name === outputItem.name);
      if (matchingFile) {
        outputItem.fileSize = matchingFile.size;
        outputItem.fileType = matchingFile.type;
      }
    }
    return outputItem;
  });
  console.log('need to add these to external', updatedFileTrainingOutput);

  try {
    axios
      .post(
        UPLOAD_DOCUMENT_TO_EXTERNAL_DB_API,
        {
          email,
          files: updatedFileTrainingOutput,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          },
        }
      )
      .then((res) => {
        console.log('res', res.data);
        dispatch({
          type: UPLOADED_DOCUMENTS,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log('err uploading document', error);
        dispatch({
          type: UPLOADED_DOCUMENTS,
          payload: [],
        });
      });
  } catch (err) {
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log('error', err.response.data);
      console.log('error', err.response.status);
    } else if (err.request) {
      console.log('error', err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};

export const uploadDocuments = (fileTrainingOutput, files) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;

  console.log('fileTrainingOutput', fileTrainingOutput);

  const postData = {
    email,
    files: fileTrainingOutput
      .filter((outputItem) => {
        return outputItem.success === true && files.some((file) => file.name === outputItem.name);
      })
      .map((outputItem) => {
        const matchingFile = files.find((file) => file.name === outputItem.name);
        return { name: matchingFile.name, type: matchingFile.type };
      }),
  };

  try {
    axios
      .post(GET_DOCUMENT_UPLOAD_URL, postData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
      })
      .then((res) => {
        res.data.forEach(async (item, index) => {
          const result = await fetch(item, {
            method: 'PUT',
            headers: {
              'Content-Type': files[index].type,
            },
            body: files[index],
          });
        });
      });
  } catch (err) {
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      console.log(err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};
