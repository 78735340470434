import * as React from 'react';
import { Button } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import Logo from '../../components/logo/Logo';
import { useGetUser } from '../../hooks/useGetUser';
import MedAffairsMenu from './MedAffairsMenu';
import useResponsive from '../../hooks/useResponsive';
import MedAffairsMenuMobile from './MedAffairsMenuMobile';

function CustomAppBar() {
  const navigate = useNavigate();
  const { user, loading } = useGetUser();
  const isDesktop = useResponsive('up', 'md');

  const logout = async () => {
    try {
      await Auth.signOut();
      navigate('/login');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  return (
    <AppBar position="static" style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
      <Container maxWidth="" sx={{ padding: '0px', pt: isDesktop ? 8 : 3 }}>
        <Toolbar disableGutters sx={{ display: 'block' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {isDesktop ? <></> : <MedAffairsMenuMobile />}

            <Logo />
            {loading ? (
              <></>
            ) : user ? (
              <>
                <Button
                  variant="container"
                  size="large"
                  sx={{
                    backgroundColor: 'white',
                    fontSize: '18px',
                    color: 'black',
                    '&:hover': { backgroundColor: '#dfdfdf' },
                  }}
                  onClick={logout}
                >
                  Logout
                </Button>
              </>
            ) : (
              <Box sx={{ display: 'flex' }}>
                {isDesktop ? <MedAffairsMenu /> : <></>}

                <Button
                  variant="container"
                  size="large"
                  sx={{
                    ml: isDesktop ? 4 : 1,
                    backgroundColor: 'white',
                    fontSize: '18px',
                    color: 'black',
                    '&:hover': { backgroundColor: '#dfdfdf' },
                  }}
                  onClick={(e) => navigate('/login')}
                >
                  Login
                </Button>
              </Box>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default CustomAppBar;
