import { Box, Typography } from '@mui/material';
import VideoLoopComponent from './VideoLoopComponent';

export default function Tab3() {
  return (
    <Box sx={{ mt: 5 }}>
      <Box className="flex justify-center align-center column">
        <Typography variant="h2">Training MedAffairs AI</Typography>
        <Typography sx={{ textAlign: 'center', fontSize: '18px' }}>
          Enhance the capabilities of MedAffairs AI by training it with your wide range of documents, including SOPs,
          SRDs, policies, publications, and more. All you need to do is upload the document and initiate the AI training
          process.
        </Typography>
      </Box>
      <Box sx={{ mt: 5 }}>
        <VideoLoopComponent
          url={'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/videos/Training.mp4'}
        />
      </Box>
    </Box>
  );
}
