import { Auth } from 'aws-amplify';

export async function updateCode(email, code, newPassword) {
  try {
    const result = await Auth.forgotPasswordSubmit(email, code, newPassword);
    console.log(result);
    if (result) {
      return { success: true, error: null };
    }
    return { success: false, error: 'Error resetting password - Please check your code' };
  } catch (error) {
    console.log('Error resetting password:', error);
    return { success: false, error: error.message || 'Unknown Error' };
  }
}
